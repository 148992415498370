import React, { useState } from "react";

export const BlogForm = () => {
  const [date, setDate] = useState("");
  const [headline, setHeadline] = useState("");
  const [accomplished, setAccomplished] = useState("");
  const [tomorrow, setTomorrow] = useState("");
  const [improvements, setImprovements] = useState("");
  const [pomo, setPomo] = useState(0);

  const resetForm = () => {
    setDate("");
    setHeadline("");
    setAccomplished("");
    setTomorrow("");
    setImprovements("");
    setPomo(0);
  };

  async function postBlog(body) {
    const settings = {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    };

    try {
      const response = await fetch("/api/v1/blogs", settings);
    } catch (error) {
      console.log("Post failed", error);
    }
  }

  const handleSubmit = event => {
    event.preventDefault();

    const payload = {
      date,
      headline,
      accomplished,
      tomorrow,
      improvements,
      pomo
    };
    postBlog(payload);
    resetForm();
  };

  return (
    <form className="dashboard__section--form" onSubmit={handleSubmit}>
      <h2>Blog</h2>
      <input
        type="date"
        placeholder="date"
        name={date}
        value={date}
        className="dashboard__section--form-input"
        onChange={e => setDate(e.target.value)}
      />

      <input
        type="text"
        placeholder="Headline"
        name={headline}
        value={headline}
        className="dashboard__section--form-input"
        onChange={e => setHeadline(e.target.value)}
      />

      <textarea
        name={accomplished}
        cols="30"
        rows="3"
        placeholder="accomplished"
        value={accomplished}
        className="dashboard__section--form-textarea"
        onChange={e => setAccomplished(e.target.value)}
      />

      <textarea
        name={tomorrow}
        cols="30"
        rows="3"
        placeholder="tomorrow"
        value={tomorrow}
        className="dashboard__section--form-textarea"
        onChange={e => setTomorrow(e.target.value)}
      />

      <input
        type="text"
        placeholder="Improve On"
        name={improvements}
        value={improvements}
        className="dashboard__section--form-input"
        onChange={e => setImprovements(e.target.value)}
      />

      <input
        type="number"
        placeholder="Pomodoro Count"
        name={pomo}
        value={pomo}
        className="dashboard__section--form-input"
        onChange={e => setPomo(e.target.value)}
      />
      <button className="dashboard__section--form-button" type="submit">
        Submit Blog
      </button>
    </form>
  );
};
