import React, { Fragment } from "react";
import ScrollAnimation from "react-animate-on-scroll";

const EsoInfo = ({ cornerValue }) => {
  const gridInfo = {
    backgroundColor: "whitesmoke",
    gridRow: "1 / span 4",
    gridColumn: "4 / span 5",
    zIndex: "1",
    boxShadow: "10px 15px 25px 0 rgba(0, 0, 0, 0.2)",
    borderRadius: cornerValue,
  };
  return (
    <Fragment>
      <ScrollAnimation animateIn="fadeInUp" style={gridInfo} animateOnce={true}>
        <div className="infoParent">
          <div className="infoChild">
            <ScrollAnimation animateIn="zoomIn" delay={400} animateOnce={true}>
              <div className="py-4">
                <h2 className="portfolio__heading portfolio__heading-eso">
                  Project Eso
                </h2>
              </div>
              <p>
                Project Eso is a capstone project from Launch Academy. The
                average American thinks about food up to 4 times a day. More so,
                if you're looking for a good place to eat around town. Enter
                Project Eso! When you create your own profile, you're able to
                choose how much you want to spend, where to spend it, and what
                kind of food you'd like to spend it on.
              </p>
              <p>
                Think Tinder meets Restaurants. The app helps narrows your
                choices. Once you've found your choice, it's saved in the
                database for future implementations.
              </p>
            </ScrollAnimation>
          </div>
        </div>
      </ScrollAnimation>
    </Fragment>
  );
};

export default EsoInfo;
