import React, { Fragment } from "react";
import SocialButtons from "./SocialButtons";

const Footer = ({ desktopColorScrollLogic }) => {
  let footerBackgroundColor;

  if (desktopColorScrollLogic === null) {
    footerBackgroundColor = "eso";
  } else {
    footerBackgroundColor = desktopColorScrollLogic.split(" ")[1];
  }

  return (
    <Fragment>
      <div className={"footer " + footerBackgroundColor}>
        <div className="footer__social">
          <SocialButtons />
        </div>
        <p className="text-center">
          <span>Copyright &copy; 2020 by Tony Phu</span>
          <span>All rights reserved</span>
        </p>
      </div>
    </Fragment>
  );
};

export default Footer;
