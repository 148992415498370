import React, { Fragment } from "react";
import ReactGA from "react-ga";
import JimmyPhoto from "./JimmyPhoto";

const gridPicture = {
  gridRow: "3 / span 3",
  gridColumn: "2 / span 3",
  zIndex: "3",
};

const gaJimmyHeroku = () => {
  ReactGA.event({
    category: "Button",
    action: "Mobile Jimmy Heroku Clicked",
  });
};

const gaJimmyGit = () => {
  ReactGA.event({
    category: "Button",
    action: "Mobile Jimmy Git Clicked",
  });
};

const gaJimmySandbox = () => {
  ReactGA.event({
    category: "Button",
    action: "Mobile Jimmy Sandbox site Clicked",
  });
};

const JimmyMobile = (props) => {
  return (
    <Fragment>
      <div
        className="container p-3"
        style={{
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))",
          borderRadius: props.cornerValue,
        }}
      >
        <div className="container p-3 jimmy-info-mobile">
          <div>
            <h2>Jimmy Estates</h2>
          </div>
          <p>
            Jimmy Estates is a CMS website designed to let the user edit 95% of
            the content site wide. This personal Real Estate agent site can be
            used as a template for other potential clients without the added
            monthly costs of other CMS companies.
            {props.readVisible && props.width < 420 && (
              <i onClick={props.handleReadMore}>... read more</i>
            )}
          </p>

          {props.more || props.width > 420 ? (
            <Fragment>
              <p>
                The website is loaded with Google Maps utility for events and
                E-Mail capability for reaching out to the agent. If you want to
                add an image or say something, go right ahead!
              </p>
              {!props.readVisible && (
                <i onClick={props.handleReadMore}>read less</i>
              )}
            </Fragment>
          ) : null}
        </div>

        <div style={{ gridPicture }} className="photo">
          <JimmyPhoto />
        </div>

        <div className="container p-3 jimmy-tech-mobile">
          <h2>Tech:</h2>

          <p>React / Ruby on Rails / PostgreSQL / Heroku / Google API</p>

          <div className="text-center">
            <div className="btn-group mr-3 mt-3">
              <a
                href="https://www.youragentjimmy.com/"
                target="_blank"
                className="btn effect01"
                onClick={gaJimmyHeroku}
              >
                <span>Live</span>
              </a>
            </div>

            <div className="btn-group mr-3 mt-3">
              <a
                href="https://jimmy-estates-sandbox.herokuapp.com/"
                target="_blank"
                className="btn effect01"
                onClick={gaJimmySandbox}
              >
                <span>Demo</span>
              </a>
            </div>

            <div className="btn-group mt-3">
              <a
                href="https://github.com/Phu-Bahr/jimmyestate"
                target="_blank"
                className="btn effect01"
                onClick={gaJimmyGit}
              >
                <span>GitHub</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default JimmyMobile;
