import React, { Fragment } from "react";

const AmazonPhoto = () => {
  return (
    <Fragment>
      <img
        className="img-fluid"
        // src="https://lh3.googleusercontent.com/pw/ACtC-3dcm5vLBVLqN8ig9lSrW1bSe5hWvKxd5yCGUUKHNI-kf568mv-zggcnRr9WZE8Dpl_ii_nqoCeidHIxPEMqOsHlXUljOolxwIRXakTByCgxmBF_LoFwYg_5WwUDFbPzjF5eS081eVB7S8X_JofuEXx6eA=w1447-h1064-no?authuser=0"
        src="https://media.giphy.com/media/X67QiL72GzBFTi1kIU/giphy.gif"
      ></img>
    </Fragment>
  );
};

export default AmazonPhoto;
