import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import Home from "./Home";
import Login from "./User/Login";

class App extends Component {
  constructor() {
    super();
    this.state = {
      loggedInStatus: "Not Logged In",
      user: {}
    };

    // Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
    ReactGA.initialize("UA-173933096-1");
    // This just needs to be called once since we have no routes in this case.
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  handleLogin = data =>
    this.setState({ loggedInStatus: "Logged In", user: data.user });

  handleLogout = () =>
    this.setState({ loggedInStatus: "Not Logged In", user: {} });

  checkLoginStatus = () => {
    fetch("/logged_in", { credentials: "include" })
      .then(response => {
        if (response.ok) {
          return response;
        } else {
          let errorMessage = `${response.status} (${response.statusText})`,
            error = new Error(errorMessage);
          throw error;
        }
      })
      .then(response => response.json())
      .then(data => {
        data.logged_in && (this.state.loggedInStatus = "Not Logged In")
          ? this.setState({ loggedInStatus: "Logged In", user: data.user })
          : data.logged_in == false && (this.state.loggedInStatus = "Logged In")
          ? this.setState({ loggedInStatus: "Not Logged In", user: {} })
          : null;
      })
      .catch(error => console.log("check login error", error));
  };

  componentDidMount = () => {
    this.checkLoginStatus();
    ReactGA.pageview(window.location.pathname);
  };

  render() {
    return (
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={props => (
              <Home
                {...props}
                loggedInStatus={this.state.loggedInStatus}
                user={this.state.user}
                handleLogin={this.handleLogin}
                handleLogout={this.handleLogout}
              />
            )}
          />
          <Route
            exact
            path="/login"
            render={props => (
              <Login
                {...props}
                loggedInStatus={this.state.loggedInStatus}
                user={this.state.user}
                handleLogin={this.handleLogin}
                handleLogout={this.handleLogout}
              />
            )}
          />
        </Switch>
      </Router>
    );
  }
}

export default App;
