import React, { Component, Fragment } from "react";
import AlertBox from "../../constants/AlertComponent";
import { postFetchEmail } from "../../constants/FetchComponent";
import styled from "styled-components";

const FormFont = styled.div`
  font-size: 1.3vw;
  color: white;

  @media only screen and (max-width: 780px) {
    font-size: 16px;
  }
`;

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      typeOfAlert: null,
    };
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  alertType = (payload) => this.setState({ typeOfAlert: payload });
  alertType2 = (payload) => {
    this.setState({ typeOfAlert: payload });
    this.props.onContactClickClose();
  };

  onSubmit = (event) => {
    event.preventDefault();
    const url = "/contacts";
    const { name, email, message } = this.state;
    const body = { name, email, message };

    postFetchEmail(url, body, this.alertType);
  };

  render() {
    return (
      <div
        className="popup"
        style={{
          opacity: this.props.opacity,
          visibility: this.props.visibility,
          zIndex: this.props.index,
        }}
      >
        <AlertBox
          {...this.state}
          alertType={this.alertType}
          alertType2={this.alertType2}
          onContactClickClose={this.props.onContactClickClose}
        />
        <div
          className="popup__content"
          style={{
            opacity: this.props.opacity,
            transform: `translate(-50%, -50%) scale(${this.props.scale})`,
          }}
        >
          <div
            className="popup__close"
            onClick={this.props.onContactClickClose}
          >
            &times;
          </div>
          <h1 className="pb-4 text-center">Let's get in touch!</h1>
          <FormFont>
            <form onSubmit={this.onSubmit}>
              <div className="form-row form">
                <div className="col-12 col-md-12 col-lg-6 form__group">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form__input"
                    onChange={this.onChange}
                    required
                    placeholder="Your Name"
                  />
                  <label htmlFor="name" className="form__label">
                    Your Name
                  </label>
                </div>
                <div className="col-12 col-md-12 col-lg-6 form__group">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form__input"
                    onChange={this.onChange}
                    required
                    placeholder="Your Email"
                  />
                  <label htmlFor="email" className="form__label">
                    Your Email
                  </label>
                </div>
              </div>
              <div className="form__group">
                <textarea
                  rows={window.innerWidth <= 680 ? 1 : 5}
                  type="text"
                  name="message"
                  id="message"
                  className="form__input form__input__text"
                  onChange={this.onChange}
                  placeholder="Want to chat? Submit a question here!"
                  required
                />
                <label htmlFor="message" className="form__label">
                  Message
                </label>
              </div>

              <div className="text-center">
                <button type="submit" className="btn effect01">
                  <span>Send</span>
                </button>
              </div>
            </form>
          </FormFont>

          <div className="contact-social">
            <div className="contact-social__icons">
              <a
                href="https://twitter.com/TonyPhuLife"
                target="_blank"
                className="contact-social__icons--icon contact-social__icons--icon-twitter"
              >
                <span className="fa fa-twitter"></span>
              </a>
              <a
                href="https://github.com/Phu-Bahr"
                target="_blank"
                className="contact-social__icons--icon contact-social__icons--icon-git"
              >
                <span className="fa fa-github"></span>
              </a>
              <a
                href="https://www.instagram.com/tony.phu.life"
                target="_blank"
                className="contact-social__icons--icon contact-social__icons--icon-insta"
              >
                <span className="fa fa-instagram"></span>
              </a>
              <a
                href="https://www.linkedin.com/in/tony-phu"
                target="_blank"
                className="contact-social__icons--icon contact-social__icons--icon-linked"
              >
                <span className="fa fa-linkedin"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactForm;
