import React, { useState } from "react";

export const TilForm = props => {
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");

  const resetForm = () => {
    setDate("");
    setDescription("");
  };

  async function postDescription(body) {
    const settings = {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    };

    try {
      const response = await fetch("/api/v1/lessons", settings);
      await props.alertType("successAdd");
    } catch (error) {
      console.log("Post failed", error);
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    const payload = { date, description };
    postDescription(payload);
    resetForm();
  };

  return (
    <form className="dashboard__section--form" onSubmit={handleSubmit}>
      <h2>Today I learned section</h2>
      <input
        type="date"
        placeholder="date"
        name={date}
        value={date}
        className="dashboard__section--form-input"
        onChange={e => setDate(e.target.value)}
      />

      <textarea
        name={description}
        value={description}
        placeholder="Today I learned..."
        cols="30"
        rows="3"
        className="dashboard__section--form-textarea"
        onChange={e => setDescription(e.target.value)}
      />
      <button className="dashboard__section--form-button" type="submit">
        Submit TIL
      </button>
    </form>
  );
};
