import React, { Fragment } from "react";
import ScrollAnimation from "react-animate-on-scroll";

const AboutPhoto = () => {
  return (
    <Fragment>
      <div className="gridPicture">
        <ScrollAnimation
          animateIn="fadeIn"
          delay={900}
          className="photo"
          animateOnce={true}
        >
          <img
            src="https://ik.imagekit.io/hawtariyzq/fullsizeoutput_75b40pex_dl3i32Ztb.jpg"
            alt="Tony Phu Picture"
          ></img>
        </ScrollAnimation>
      </div>
    </Fragment>
  );
};

export default AboutPhoto;
