import React, { Fragment } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import ReactGA from "react-ga";

const gaEsoHeroku = () => {
  ReactGA.event({
    category: "Button",
    action: "Eso Heroku Clicked",
  });
};

const gaEsoGit = () => {
  ReactGA.event({
    category: "Button",
    action: "Eso Git Clicked",
  });
};

const EsoTech = ({ cornerValue }) => {
  const gridTech = {
    backgroundColor: "black",
    gridRow: "4 / span 3",
    gridColumn: "3 / span 5",
    zIndex: "2",
    boxShadow: "10px 15px 25px 0 rgba(0, 0, 0, 0.2)",
    borderRadius: cornerValue,
    marginTop: "30px",
    display: "grid",
    gridTemplateColumns: "36.5% 1fr",
    alignItems: "center",
  };

  return (
    <Fragment>
      <ScrollAnimation
        animateIn="fadeInRight"
        style={gridTech}
        delay={550}
        animateOnce={true}
      >
        <div className="techParent">
          <div className="techChild" style={{ color: "white" }}>
            <ScrollAnimation animateIn="fadeIn" delay={600} animateOnce={true}>
              <h2>Tech:</h2>

              <p>React / Ruby on Rails / PostgreSQL / Heroku / Yelp API</p>

              <div className="btn-group mr-3">
                <a
                  href="http://www.projecteso.com/"
                  target="_blank"
                  className="btn effect01"
                  onClick={gaEsoHeroku}
                >
                  <span>Demo</span>
                </a>
              </div>

              <div className="btn-group">
                <a
                  href="https://github.com/Phu-Bahr/project-eso"
                  target="_blank"
                  className="btn effect01"
                  onClick={gaEsoGit}
                >
                  <span>GitHub</span>
                </a>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </ScrollAnimation>
    </Fragment>
  );
};

export default EsoTech;
