import React, { Fragment } from "react";
import ScrollAnimation from "react-animate-on-scroll";

const JimmyInfo = (props) => {
  const gridInfo = {
    backgroundColor: "#5CDA95",
    gridRow: "1 / span 4",
    gridColumn: "4 / span 5",
    zIndex: "1",
    boxShadow: "10px 15px 25px 0 rgba(0, 0, 0, 0.2)",
    borderRadius: props.cornerValue,
  };

  return (
    <Fragment>
      <ScrollAnimation
        animateIn="fadeInDown"
        style={gridInfo}
        animateOnce={true}
      >
        <div className="infoParent">
          <div className="infoChild">
            <ScrollAnimation animateIn="fadeIn" delay={600} animateOnce={true}>
              <div className="py-4">
                <h2 className="portfolio__heading portfolio__heading-jimmy">
                  Jimmy Estates
                </h2>
              </div>
              <p>
                Jimmy Estates is a CMS website designed to let the user edit 95%
                of the content site wide. This personal Real Estate agent site
                can be used as a template for other potential clients without
                the added monthly costs of other CMS companies.
              </p>
              <p>
                The website is loaded with Google Maps utility for events and
                E-Mail capability for reaching out to the agent. If you want to
                add an image or say something, go right ahead!
              </p>
            </ScrollAnimation>
          </div>
        </div>
      </ScrollAnimation>
    </Fragment>
  );
};

export default JimmyInfo;
