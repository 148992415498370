import React, { Component, Fragment } from "react";
import About from "./About/About";
import EsoContainer from "./Eso/EsoContainer";
import JimmyEstate from "./JImmyEstate/JimmyEstate";
import AmazonContainer from "./Amazon/AmazonContainer";
import Footer from "./Footer/Footer";
import Navbar from "./Navbar/Navbar";
import { Transition, FadeIn } from "../constants/Styled";
import { BlogContainer } from "./Blog/BlogContainer";
import ContactForm from "./Footer/ContactForm";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollPos: 0,
      height: 0,
      // contact popup state
      // opacity: 1,
      // visibility: "visible",
      // scale: 1,
      // index: 1000,
      opacity: 0,
      visibility: "invisible",
      scale: 0.25,
      index: -1000,
    };
  }

  onContactClick = () => {
    this.setState({ opacity: 1, visibility: "visible", scale: 1, index: 9999 });
  };

  onContactClickClose = () => {
    this.setState({
      opacity: 0,
      visibility: "invisible",
      scale: 0.25,
      index: -1000,
    });
  };

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  };
  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  };

  updateWindowDimensions = () => this.setState({ height: window.innerHeight });

  handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    this.setState({ scrollPos: currentScrollPos });
  };

  render() {
    // console.log("scroll position ", this.state.scrollPos);
    // console.log("height of screen ", this.state.height);

    //desktop
    let desktopColorScrollLogic =
      this.state.scrollPos < this.state.height * 1.45
        ? "panel about"
        : this.state.scrollPos <= this.state.height * 2.45
        ? "panel jimmy"
        : this.state.scrollPos <= this.state.height * 3.45
        ? "panel amazon"
        : this.state.scrollPos <= this.state.height * 4.45
        ? "panel eso"
        : this.state.scrollPos <= this.state.height * 10
        ? "panel blog-section"
        : null;

    //mobile
    if (window.innerWidth <= 680 && window.innerHeight <= 800) {
      desktopColorScrollLogic =
        this.state.scrollPos < this.state.height * 2.2
          ? "panel about"
          : this.state.scrollPos <= this.state.height * 3.2
          ? "panel jimmy"
          : this.state.scrollPos <= this.state.height * 4.2
          ? "panel amazon"
          : this.state.scrollPos <= this.state.height * 5.2
          ? "panel eso"
          : this.state.scrollPos <= this.state.height * 10
          ? "panel blog-section"
          : null;
    }

    //ipad
    if (window.innerWidth > 680 && window.innerWidth <= 990) {
      desktopColorScrollLogic =
        this.state.scrollPos < this.state.height * 1.8
          ? "panel about"
          : this.state.scrollPos <= this.state.height * 2.8
          ? "panel jimmy"
          : this.state.scrollPos <= this.state.height * 3.8
          ? "panel amazon"
          : this.state.scrollPos <= this.state.height * 4.8
          ? "panel eso"
          : this.state.scrollPos <= this.state.height * 10
          ? "panel blog-section"
          : null;
    }

    return (
      <Fragment>
        <Transition>
          <FadeIn>
            <Navbar onContactClick={this.onContactClick} />

            <div className={desktopColorScrollLogic}>
              <About onContactClick={this.onContactClick} />
              <JimmyEstate />
              <AmazonContainer />
              <EsoContainer />
              <BlogContainer
                desktopColorScrollLogic={desktopColorScrollLogic}
                user={this.props.user}
              />
            </div>
            <Footer desktopColorScrollLogic={desktopColorScrollLogic} />
            <ContactForm
              {...this.state}
              onContactClickClose={this.onContactClickClose}
            />
          </FadeIn>
        </Transition>
      </Fragment>
    );
  }
}

export default Home;
