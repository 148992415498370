import React, { Fragment } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-scroll";
import ReactGA from "react-ga";

const AboutMobile = props => {
  const gaAboutMobile = () => {
    ReactGA.event({
      category: "Button",
      action: "About contact me on mobile Clicked"
    });
    props.onContactClick();
  };
  return (
    <Fragment>
      <div className="container pt-5 text-center">
        <ScrollAnimation
          animateIn="fadeIn"
          className="pb-3 photo"
          animateOnce={true}
        >
          <img
            className="img-fluid"
            src="https://ik.imagekit.io/hawtariyzq/fullsizeoutput_75b40pex_dl3i32Ztb.jpg"
          ></img>
        </ScrollAnimation>
      </div>
      <div className="container">
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <p className="pb-2" style={{ fontWeight: "700" }}>
            Hi, I am a freelance front end and back end software developer based
            out of Boston, MA, in pursuit of a career in the tech industry.
          </p>

          <p>
            I wake up and sleep thinking about code, wondering what I can add
            next, how to debug something, or just fixing that alignment by 5
            more pixels. Learning new techniques to implement into my projects
            excites me like getting that amazon package you forgot about.
            Debugging errors to me is like fighting a new boss in a video game.
            You analyze him, strategize, then follow through trial and error
            until he's defeated.
          </p>

          <p>
            My past work experiences involve network marketing, managing
            operations in a global custodian bank, completing a coding bootcamp,
            and most recently learning data engineering and software development
            at one of the top government SaaS companies.
          </p>

          <p>
            Any free time I have available is designated to reading up on new
            changes within the industry, taking online courses to further my
            knowledge, spending time with my family, connecting with friends,
            and helping you build something extraordinary.
          </p>

          <Link
            to="footerTag"
            smooth={true}
            offset={10}
            duration={1100}
            className="navFontItem"
            onClick={gaAboutMobile}
          >
            <div style={{ color: "black", textDecoration: "underline" }}>
              Let’s build something together.
            </div>
          </Link>
        </ScrollAnimation>
      </div>
    </Fragment>
  );
};

export default AboutMobile;
