import React, { useState, useEffect, Fragment } from "react";
import dayjs from "dayjs";
import { TodayContainer } from "./TodayContainer";
import AlertBox from "../../constants/AlertComponent";
import Tilt from "react-parallax-tilt";
import { ImposterContainer } from "./ImposterContainer";

export const BlogContainer = (props) => {
  const [til, setTil] = useState([]);
  const [imposter, setImposter] = useState([]);
  const [typeOfAlert, setTypeOfAlert] = useState(null);
  //to show or hide TIL popup
  const [opacityToday, setOpacityToday] = useState(0);
  const [visibilityToday, setVisibilityToday] = useState("invisible");
  const [scaleToday, setScaleToday] = useState(0.25);
  const [indexToday, setIndexToday] = useState(-2000);
  // const [opacityToday, setOpacityToday] = useState(1);
  // const [visibilityToday, setVisibilityToday] = useState("visible");
  // const [scaleToday, setScaleToday] = useState(1);
  // const [indexToday, setIndexToday] = useState(9999);
  const [opacityImposter, setOpacityImposter] = useState(0);
  const [visibilityImposter, setVisibilityImposter] = useState("invisible");
  const [scaleImposter, setScaleImposter] = useState(0.25);
  const [indexImposter, setIndexImposter] = useState(-2000);

  const tempPhrase = "Daily Blog and Article coming soon...";
  useEffect(() => {
    const url = "/api/v1/lessons";
    const url2 = "/api/v1/imposters";
    async function fetchTIL() {
      try {
        const response = await Promise.all([fetch(url), fetch(url2)]);
        const data1 = await response[0].json();
        const data2 = await response[1].json();
        setTil(data1);
        setImposter(data2);
      } catch (error) {
        console.log(error);
      }
    }
    fetchTIL();
  }, []);

  const alertType = (payload) => setTypeOfAlert(payload);

  const onTodayClick = () => {
    setOpacityToday(1);
    setVisibilityToday("visible");
    setScaleToday(1);
    setIndexToday(9999);
  };

  const onTodayClickClose = () => {
    setOpacityToday(0);
    setVisibilityToday("invisible");
    setScaleToday(0.25);
    setIndexToday(-1000);
  };
  const onImposterClick = () => {
    setOpacityImposter(1);
    setVisibilityImposter("visible");
    setScaleImposter(1);
    setIndexImposter(9999);
  };

  const onImposterClickClose = () => {
    setOpacityImposter(0);
    setVisibilityImposter("invisible");
    setScaleImposter(0.25);
    setIndexImposter(-1000);
  };

  let cardBackgroundColor;
  if (props.desktopColorScrollLogic === null) {
    cardBackgroundColor = "blog-section";
  } else {
    cardBackgroundColor = props.desktopColorScrollLogic.split(" ")[1];
  }

  let firstDate = dayjs(til[0] && til[0].date).format("MMMM DD, YYYY");
  let firstLesson = til[0] && til[0].description;

  if (!til) {
    return <div>Loading</div>;
  }

  return (
    <Fragment>
      <TodayContainer
        opacityToday={opacityToday}
        visibilityToday={visibilityToday}
        scaleToday={scaleToday}
        indexToday={indexToday}
        til={til}
        setTil={setTil}
        onTodayClickClose={onTodayClickClose}
        alertType={alertType}
        user={props.user}
      />
      <ImposterContainer
        opacityImposter={opacityImposter}
        visibilityImposter={visibilityImposter}
        scaleImposter={scaleImposter}
        indexImposter={indexImposter}
        imposter={imposter}
        setImposter={setImposter}
        onImposterClickClose={onImposterClickClose}
        alertType={alertType}
        user={props.user}
      />
      <AlertBox typeOfAlert={typeOfAlert} alertType={alertType} />
      <section className="blog container" id="blogTag">
        <div className="blog__position ">
          <div className="text-center pb-4">
            <h2>Blog section</h2>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4 p-3">
              <div className={"card " + cardBackgroundColor}>
                <div id="til-1" className="card__side card__side--front">
                  <div className="card__picture card__picture--1">&nbsp;</div>
                  <h3 className="card__heading">
                    <span className="card__heading-span card__heading-span--1">
                      Today I Learned
                    </span>
                  </h3>
                  <div className="card__details">
                    Tidbits of the Programming World
                  </div>
                </div>

                {/* back of card */}
                <div className="card__side card__side--back card__side--back-1">
                  <div className="card__cta">
                    <div className="card__details-box">
                      <div className="card__details-date">{firstDate}</div>
                      <p className="card__details-quote">"{firstLesson}"</p>
                    </div>
                    <a onClick={onTodayClick} style={{ cursor: "pointer" }}>
                      Previous TIL's
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-4 p-3">
              {window.innerWidth <= 1100 ? (
                <div className={"card blogCard " + cardBackgroundColor}>
                  <div className="card__side">
                    <div className="card__picture card__picture--2">&nbsp;</div>
                    <h3 className="card__heading">
                      <span className="card__heading-span card__heading-span--2">
                        Blog Posts
                      </span>
                    </h3>

                    <div className="card__details">{tempPhrase}</div>
                  </div>
                </div>
              ) : (
                <Tilt
                  className={"card blogCard " + cardBackgroundColor}
                  tiltMaxAngleX={10}
                  tiltMaxAngleY={55}
                  tiltReverse={true}
                  perspective={1500}
                  transitionSpeed={3000}
                  scale={1.1}
                  style={{
                    transformStyle: "preserve-3d",
                  }}
                  id="card-blog"
                >
                  <div
                    className="card__side"
                    style={{ transform: "translateZ(50px)" }}
                  >
                    <div className="card__picture card__picture--2">&nbsp;</div>
                    <h3 className="card__heading">
                      <span className="card__heading-span card__heading-span--2">
                        Blog Posts
                      </span>
                    </h3>

                    <div className="card__details">{tempPhrase}</div>
                  </div>
                </Tilt>
              )}
            </div>

            <div
              className="col-sm-12 col-md-6 col-lg-4 p-3"
              onClick={onImposterClick}
              style={{ cursor: "pointer" }}
            >
              <div className={"card " + cardBackgroundColor}>
                <div className="card__side card__side-3">
                  <div className="card__picture card__picture--3">&nbsp;</div>
                  <h3 className="card__heading">
                    <span className="card__heading-span card__heading-span--3">
                      !mposter Syndrome
                    </span>
                  </h3>
                  <div className="card__details">
                    My wins and AH HA moments. Coming soon...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
