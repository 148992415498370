import React, { Fragment } from "react";
import ScrollAnimation from "react-animate-on-scroll";

const AmazonInfo = ({ cornerValue }) => {
  const gridInfo = {
    backgroundColor: "white",
    gridRow: "1 / span 4",
    gridColumn: "4 / span 5",
    zIndex: "1",
    boxShadow: "10px 15px 25px 0 rgba(0, 0, 0, 0.2)",
    borderRadius: cornerValue,
  };
  return (
    <Fragment>
      <ScrollAnimation
        animateIn="fadeInLeft"
        style={gridInfo}
        animateOnce={true}
      >
        <div className="infoParent">
          <div className="infoChild">
            <ScrollAnimation animateIn="zoomIn" delay={400} animateOnce={true}>
              <div className="pb-5">
                <h2 className="portfolio__heading portfolio__heading-amazon">
                  Amazonion
                </h2>
              </div>
              <p>
                Are you an Amazon addict? Find out and use this app! Amazonion
                will take the last 15 years of your purchase history and display
                it beautifully on 3 custom charts. If you want to dive deeper,
                there's also a table for you to sort through all your items.
                Have fun with it!
              </p>
            </ScrollAnimation>
          </div>
        </div>
      </ScrollAnimation>
    </Fragment>
  );
};

export default AmazonInfo;
