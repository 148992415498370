import styled, { keyframes } from "styled-components";
import {
  fadeIn,
  fadeInUp,
  fadeInDown,
  fadeInRight,
  fadeInLeft,
  fadeOut,
} from "react-animations";

export const FadeIn = styled.div`
  animation: 1s ${keyframes`${fadeIn}`};
`;

export const FadeInUp = styled.div`
  animation: 1s ${keyframes`${fadeInUp}`};
`;

export const FadeInDown = styled.div`
  animation: 1s ${keyframes`${fadeInDown}`};
`;

export const FadeInRight = styled.div`
  animation: 1s ${keyframes`${fadeInRight}`};
`;

export const FadeInLeft = styled.div`
  animation: 1s ${keyframes`${fadeInLeft}`};
`;

export const FadeOut = styled.div`
  animation: 1s ${keyframes`${fadeOut}`};
`;

export const Transition = styled.div`
  .about {
    background-color: #caf0f8;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .eso {
    background-color: #781d2d;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .amazon {
    background-color: #ff9900;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .jimmy {
    background-color: #edf5e1;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .blog-section {
    background-color: #e9ecef;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
`;

// Nour Ibram css-buttons
export const FooterButtons = styled.div`
  ul {
    position: relative;
    display: flex;
  }
  ul li {
    position: relative;
    list-style: none;
    width: 100px;
    height: 100px;
    margin: 0px 25px;
  }

  ul li span {
    position: absolute;
    top: 0;
    lef: 0;
    width: 100%;
    height: 100%;
    display: flex !important;
    background: #2a2a2a;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 50px !important;
    transition: 1.5s ease-out;
    border-radius: 5px;
  }

  ul li:hover span {
    z-index: 1000;
    transition: 0.3s;
    color: #fff;
    box-shadw: -1px 1px 1px rgba(0, 0, 0, 0.5);
  }
  ul li:hover span:nth-child(5) {
    transform: translate(40px, -40px);
    opacity: 1;
  }
  ul li:hover span:nth-child(4) {
    transform: translate(30px, -30px);
    opacity: 0.8;
  }
  ul li:hover span:nth-child(3) {
    transform: translate(20px, -20px);
    opacity: 0.6;
  }
  ul li:hover span:nth-child(2) {
    transform: translate(10px, -10px);
    opacity: 0.4;
  }
  ul li:hover span:nth-child(1) {
    transform: translate(0px, 0px);
    opacity: 0.2;
  }
  ul li:nth-child(1):hover span {
    background: #1fa1f1 !important;
  }
  ul li:nth-child(2):hover span {
    background: #2c3456 !important;
  }
  ul li:nth-child(3):hover span {
    background: linear-gradient(
      216deg,
      rgba(64, 93, 230, 1) 0%,
      rgba(225, 48, 108, 1) 54%,
      rgba(255, 220, 128, 1) 100%
    ) !important;
  }
  ul li:nth-child(4):hover span {
    background: #0177b5 !important;
  }
  @media only screen and (max-width: 780px) {
    ul {
      position: relative;
      display: flex;
      transform-style: preserve-3d;
      margin-left: -25px;
    }

    ul li {
      position: relative;
      list-style: none;
      width: 40px;
      height: 40px;
      margin: 0px 10px;
    }
  }
`;
