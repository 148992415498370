import React, { Component, Fragment } from "react";
import AboutMobile from "./AboutMobile";
import Boxes from "./Boxes";
import AboutInfo from "./AboutInfo";
import AboutPhoto from "./AboutPhoto";

class About extends Component {
  constructor() {
    super();
    this.state = {
      width: 0
    };
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions);
  };

  updateWindowDimensions = () => this.setState({ width: window.innerWidth });

  render() {
    // console.log("width of screen", this.state.width);

    return (
      <Fragment>
        <div id="aboutTag" className="pb-5">
          {this.state.width > 990 ? (
            <div className="gridAboutContainer">
              <AboutPhoto />
              <AboutInfo onContactClick={this.props.onContactClick} />
              <Boxes width={this.state.width} />
            </div>
          ) : (
            <AboutMobile onContactClick={this.props.onContactClick} />
          )}
        </div>
      </Fragment>
    );
  }
}

export default About;
