import React, { Component, Fragment } from "react";
import EsoMobile from "./EsoMobile";
import EsoInfo from "./EsoInfo";
import EsoTech from "./EsoTech";
import EsoPhoto from "./EsoPhoto";
import ScrollAnimation from "react-animate-on-scroll";

const gridPicture = {
  gridRow: "3 / span 2",
  gridColumn: "2 / span 3",
  zIndex: "3"
};

class EsoContainer extends Component {
  constructor() {
    super();
    this.state = {
      width: 0,
      more: false,
      readVisible: true
    };
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  };
  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions);
  };

  updateWindowDimensions = () => this.setState({ width: window.innerWidth });

  handleReadMore = () =>
    this.setState({
      more: !this.state.more,
      readVisible: !this.state.readVisible
    });

  render() {
    const cornerValue = "5px";
    const gridBackground = {
      backgroundImage:
        "linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))",
      gridRow: "2 / span 4",
      gridColumn: "1 / span 9",
      zIndex: "0",
      boxShadow: "10px 15px 25px 0 rgba(0, 0, 0, 0.2)",
      borderRadius: cornerValue
    };

    return (
      <Fragment>
        <div>
          {this.state.width > 990 ? (
            <div className="gridContainer">
              <div style={gridBackground} />
              <ScrollAnimation
                animateIn="fadeInLeft"
                style={gridPicture}
                delay={500}
                className="photo"
                animateOnce={true}
              >
                <EsoPhoto cornerValue={cornerValue} />
              </ScrollAnimation>
              <EsoInfo cornerValue={cornerValue} />
              <EsoTech cornerValue={cornerValue} />
            </div>
          ) : (
            <div className="pb-5">
              <EsoMobile
                {...this.state}
                handleReadMore={this.handleReadMore}
                cornerValue={cornerValue}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default EsoContainer;
