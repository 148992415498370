import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Link, animateScroll as scroll } from "react-scroll";
import ReactGA from "react-ga";
// import Cover from "../../../assets/images/cover.mp4";

const StyledNavbar = styled.div`
  position: fixed;
  width: 100%;
  margin: 0 auto;
  z-index: 1000;
`;

//for navbar
const Transition = styled.div`
  .active {
    padding: 20%;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .hidden {
    height: none;
    background-color: #111;
    opacity: 0.8;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  @media only screen and (max-width: 768px) {
    .active {
      padding: 50%;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }
  }
  @media only screen and (max-width: 500px) {
    .active {
      padding: 80%;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }
  }
`;
// const Transition = styled.div`
//   .active {
//     padding: 18%;
//     -webkit-transition: all 0.4s ease;
//     transition: all 0.4s ease;
//   }
//   .hidden {
//     background-color: #111;
//     opacity: 0.8;
//     -webkit-transition: all 0.4s ease;
//     transition: all 0.4s ease;
//   }
//   @media only screen and (max-width: 768px) {
//     .active {
//       padding-top: 40%;
//       -webkit-transition: all 0.4s ease;
//       transition: all 0.4s ease;
//     }
//   }
//   @media only screen and (max-width: 500px) {
//     .active {
//       padding-top: 60%;
//       -webkit-transition: all 0.4s ease;
//       transition: all 0.4s ease;
//     }
//   }
// `;

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      scrollPos: 0,
    };
  }

  scrollToTop = () => {
    scroll.scrollToTop();
    ReactGA.event({
      category: "Button",
      action: "Nav Home Clicked",
    });
  };

  componentDidMount = () =>
    window.addEventListener("scroll", this.handleScroll);

  componentWillUnmount = () =>
    window.removeEventListener("scroll", this.handleScroll);

  handleScroll = () => {
    const { scrollPos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const show = scrollPos > currentScrollPos;

    this.setState({
      scrollPos: currentScrollPos,
      show,
    });
  };

  gaAbout = () => {
    ReactGA.event({
      category: "Button",
      action: "Nav About Clicked",
    });
  };
  gaPortfolio = () => {
    ReactGA.event({
      category: "Button",
      action: "Nav Portfolio Clicked",
    });
  };
  gaBlog = () => {
    ReactGA.event({
      category: "Button",
      action: "Nav Blog Clicked",
    });
  };
  gaContact = () => {
    ReactGA.event({
      category: "Button",
      action: "Nav Contact Clicked",
    });
    this.props.onContactClick();
  };
  gaNavHero = () => {
    ReactGA.event({
      category: "Button",
      action: "Nav NavHero Clicked",
    });
  };

  render() {
    let navLists = (
      <Fragment>
        <div className="box-wrap">
          <li className="box">
            <Link
              to="aboutTag"
              smooth={true}
              offset={10}
              duration={1100}
              className="nav-link navFontItem"
              onClick={this.gaAbout}
            >
              About
            </Link>
          </li>

          <li className="box">
            <Link
              to="esoTag"
              smooth={true}
              offset={-80}
              duration={1100}
              className="nav-link navFontItem"
              onClick={this.gaPortfolio}
            >
              Portfolio
            </Link>
          </li>

          <li className="box">
            <Link
              to="blogTag"
              smooth={true}
              offset={-80}
              duration={1100}
              className="nav-link navFontItem"
              onClick={this.gaBlog}
            >
              Blog
            </Link>
          </li>

          <li className="box">
            <a className="nav-link navFontItem" onClick={this.gaContact}>
              Contact
            </a>
          </li>
        </div>
      </Fragment>
    );

    let collapseMenuLogic;
    if (window.innerWidth < 991) {
      collapseMenuLogic = (
        <div className="text-center">
          <ul
            className="navbar-nav ml-auto"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
          >
            {navLists}
          </ul>
        </div>
      );
    } else {
      collapseMenuLogic = <ul className="navbar-nav ml-auto">{navLists}</ul>;
    }

    return (
      <Fragment>
        <Transition>
          <StyledNavbar
            className={this.state.scrollPos < 70 ? "active" : "hidden"}
          >
            <nav className="navbar navbar-expand-lg navbar-dark site-header px-5">
              {this.state.scrollPos < 70 ? null : (
                <Fragment>
                  <div className="navFont1" onClick={this.scrollToTop}>
                    Tony Phu
                  </div>
                  {/* <LinkR to="/login">Tony Phu</LinkR> */}

                  <button
                    type="button"
                    className="navbar-toggler"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    {collapseMenuLogic}
                  </div>
                </Fragment>
              )}
            </nav>
          </StyledNavbar>
        </Transition>

        <section className="bg-video" style={{ position: "relative" }}>
          {this.state.scrollPos > 69 ? null : (
            <Link to="aboutTag" smooth={true} offset={-20} duration={1000}>
              <div className="hero-title-wrapper" onClick={this.gaNavHero}>
                <div className="navFont line-1 anim-typewriter">
                  Hi I'm Tony
                </div>

                <div className="navFont2 text-center hide1">
                  Developer | Father | Husband | Gamer
                </div>

                {window.innerWidth <= 768 && (
                  <div className="text-center row">
                    <div className="navFont2 col-sm-12">Developer</div>
                    <div className="navFont2 col-sm-12">Father</div>
                    <div className="navFont2 col-sm-12">Husband</div>
                    <div className="navFont2 col-sm-12">Gamer</div>
                  </div>
                )}
              </div>
            </Link>
          )}
          {window.innerWidth <= 680 ? (
            <section className="home"></section>
          ) : (
            <video className="bg-video__content" autoPlay muted loop>
              <source
                src="https://tonyp-videos.s3.amazonaws.com/cover.mp4"
                type="video/mp4"
                className=""
              />
            </video>
          )}
        </section>
      </Fragment>
    );
  }
}

export default Navbar;
