import React, { Fragment } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import ReactGA from "react-ga";

const gaAmazonHeroku = () => {
  ReactGA.event({
    category: "Button",
    action: "Amazon Heroku Clicked",
  });
};

const gaAmazonGit = () => {
  ReactGA.event({
    category: "Button",
    action: "Amazon Git Clicked",
  });
};

const AmazonTech = ({ cornerValue }) => {
  const gridTech = {
    backgroundColor: "#31c4f3",
    gridRow: "4 / span 3",
    gridColumn: "3 / span 5",
    zIndex: "2",
    boxShadow: "10px 15px 25px 0 rgba(0, 0, 0, 0.2)",
    borderRadius: cornerValue,
    marginTop: "30px",
    display: "grid",
    gridTemplateColumns: "36.5% 1fr",
    alignItems: "center",
  };

  return (
    <Fragment>
      <ScrollAnimation
        animateIn="fadeInDown"
        style={gridTech}
        delay={550}
        animateOnce={true}
      >
        <div className="techParent">
          <div className="techChild" style={{ color: "white" }}>
            <ScrollAnimation animateIn="fadeIn" delay={600} animateOnce={true}>
              <h2>Tech:</h2>

              <p>React / Next JS / Nivo Charts / Vercel / DropZone</p>

              <div className="btn-group mr-3">
                <a
                  href="https://amazonion.me/"
                  target="_blank"
                  className="btn effect01"
                  onClick={gaAmazonHeroku}
                >
                  <span>Demo</span>
                </a>
              </div>

              <div className="btn-group">
                <a
                  href="https://github.com/Phu-Bahr/amazonion"
                  target="_blank"
                  className="btn effect01"
                  onClick={gaAmazonGit}
                >
                  <span>GitHub</span>
                </a>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </ScrollAnimation>
    </Fragment>
  );
};

export default AmazonTech;
