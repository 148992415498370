import React, { Fragment } from "react";
import ScrollAnimation from "react-animate-on-scroll";

const cornerValue = "5px";

const gridBackground = {
  backgroundImage:
    "linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7))",
  gridRow: "5 / span 6",
  gridColumn: "1 / -1",
  zIndex: "0",
  borderRadius: cornerValue
  // boxShadow: "10px 15px 25px 0 rgba(0, 0, 0, 0.2)"
};

const box4 = {
  backgroundColor: "#03045E",
  gridRow: "6 / span 5",
  gridColumn: "5 / span 4",
  zIndex: "4",
  borderRadius: cornerValue
};
const box5 = {
  backgroundColor: "#023E8A",
  gridRow: "2 / span 4",
  gridColumn: "7 / span 5",
  zIndex: "5",
  borderRadius: cornerValue
};
const box6 = {
  backgroundColor: "#0077B6",
  gridRow: "1 / span 4",
  gridColumn: "4 / span 6",
  zIndex: "6",
  borderRadius: cornerValue
};
const box7 = {
  backgroundColor: "#0096C7",
  gridRow: "3 / span 6",
  gridColumn: "6 / span 3",
  zIndex: "7",
  borderRadius: cornerValue
};
const box8 = {
  backgroundColor: "#90E0EF",
  gridRow: "4 / span 6",
  gridColumn: "3 / span 6",
  zIndex: "8",
  borderRadius: cornerValue
};
const box9 = {
  backgroundColor: "#ADE8F4",
  gridRow: "6 / span 5",
  gridColumn: "8 / span 6",
  zIndex: "9",
  borderRadius: cornerValue
};

const Boxes = ({ width }) => {
  const backgroundBoxes = color => {
    return width > 990 && width < 1100
      ? {
          backgroundColor: color,
          gridRow: "2 / span 6",
          gridColumn: "2 / span 10",
          zIndex: "0",
          borderRadius: cornerValue
        }
      : {
          backgroundColor: color,
          gridRow: "2 / span 8",
          gridColumn: "3 / span 6",
          zIndex: "0",
          borderRadius: cornerValue
        };
  };

  const box10 = backgroundBoxes("#ADE8F4");
  const box11 = backgroundBoxes("#023E8A");
  const box12 = backgroundBoxes("#0077B6");
  const box13 = backgroundBoxes("#0096C7");
  const box14 = backgroundBoxes("#90E0EF");
  const box15 = backgroundBoxes("#03045E");

  return (
    <Fragment>
      <ScrollAnimation
        animateIn="fadeIn"
        style={gridBackground}
        delay={900}
        className="boxShadow"
        animateOnce={true}
      ></ScrollAnimation>
      <ScrollAnimation
        animateIn="fadeOutDown"
        initiallyVisible={true}
        style={box4}
        delay={100}
        animateOnce={true}
      ></ScrollAnimation>
      <ScrollAnimation
        animateIn="fadeOutUp"
        initiallyVisible={true}
        style={box5}
        delay={500}
        animateOnce={true}
      ></ScrollAnimation>
      <ScrollAnimation
        animateIn="fadeOutLeft"
        initiallyVisible={true}
        style={box6}
        delay={300}
        animateOnce={true}
      ></ScrollAnimation>
      <ScrollAnimation
        animateIn="fadeOutUp"
        initiallyVisible={true}
        style={box7}
        delay={200}
        animateOnce={true}
      ></ScrollAnimation>
      <ScrollAnimation
        animateIn="fadeOutLeft"
        initiallyVisible={true}
        style={box8}
        delay={300}
        animateOnce={true}
      ></ScrollAnimation>
      <ScrollAnimation
        animateIn="fadeOutDown"
        initiallyVisible={true}
        style={box9}
        delay={400}
        animateOnce={true}
      ></ScrollAnimation>
      <ScrollAnimation
        animateIn="fadeInLeft"
        style={box10}
        delay={1400}
        animateOnce={true}
      ></ScrollAnimation>
      <ScrollAnimation
        animateIn="fadeInLeft"
        style={box11}
        delay={1600}
        animateOnce={true}
      ></ScrollAnimation>
      <ScrollAnimation
        animateIn="fadeInLeft"
        style={box12}
        delay={1800}
        animateOnce={true}
      ></ScrollAnimation>
      <ScrollAnimation
        animateIn="fadeInRight"
        style={box13}
        delay={1400}
        animateOnce={true}
      ></ScrollAnimation>
      <ScrollAnimation
        animateIn="fadeInRight"
        style={box14}
        delay={1600}
        animateOnce={true}
      ></ScrollAnimation>
      <ScrollAnimation
        animateIn="fadeInRight"
        style={box15}
        delay={1800}
        animateOnce={true}
      ></ScrollAnimation>
    </Fragment>
  );
};

export default Boxes;
