import React, { Fragment } from "react";
import { FooterButtons } from "../../constants/Styled";
import ReactGA from "react-ga";

const gaTwitter = () => {
  ReactGA.event({
    category: "Button",
    action: "Twitter Clicked"
  });
};
const gaGithub = () => {
  ReactGA.event({
    category: "Button",
    action: "Github Clicked"
  });
};
const gaInsta = () => {
  ReactGA.event({
    category: "Button",
    action: "Insta Clicked"
  });
};
const gaLinkedIn = () => {
  ReactGA.event({
    category: "Button",
    action: "LinkedIn Clicked"
  });
};

const SocialButtons = () => {
  return (
    <Fragment>
      <FooterButtons>
        <ul>
          <li>
            <a
              href="https://twitter.com/TonyPhuLife"
              target="_blank"
              onClick={gaTwitter}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span className="fa fa-twitter"></span>
            </a>
          </li>
          <li>
            <a
              href="https://github.com/Phu-Bahr"
              target="_blank"
              onClick={gaGithub}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span className="fa fa-github"></span>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/tony.phu.life"
              target="_blank"
              onClick={gaInsta}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span className="fa fa-instagram"></span>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/tony-phu"
              target="_blank"
              onClick={gaLinkedIn}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span className="fa fa-linkedin"></span>
            </a>
          </li>
        </ul>
      </FooterButtons>
    </Fragment>
  );
};

export default SocialButtons;
