import React, { useState } from "react";
import dayjs from "dayjs";
import AlertBox from "../../constants/AlertComponent";

export const TodayContainer = props => {
  let dateFormat = date => dayjs(date).format("MM/DD/YYYY");
  const [typeOfAlert, setTypeOfAlert] = useState(null);

  const alertType = payload => setTypeOfAlert(payload);

  const deleteLesson = async id => {
    const settings = {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
        "Content-Type": "application/json"
      }
    };

    try {
      const response = await fetch(`/api/v1/lessons/${id}`, settings);
      const data = await response.json();
      await props.setTil(data);

      await alertType("successDelete");
    } catch (error) {
      console.log("Delete failed", error);
    }
  };

  return (
    <div
      className="popup"
      style={{
        opacity: props.opacityToday,
        visibility: props.visibilityToday,
        zIndex: props.indexToday
      }}
    >
      <AlertBox typeOfAlert={typeOfAlert} alertType={alertType} />
      <div
        className="popup__content-lesson"
        style={{
          opacity: props.opacityToday,
          transform: `translate(-50%, -50%) scale(${props.scaleToday})`
        }}
      >
        <div className="popup__close" onClick={props.onTodayClickClose}>
          &times;
        </div>
        <h1 className="pb-4 text-center">Yesterday I learned</h1>
        <div className="popup__lessons">
          {props.til &&
            props.til.map(element => (
              <div key={element.id} className="popup__lessons--group">
                {props.user.admin && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-trash"
                    viewBox="0 0 16 16"
                    onClick={() => deleteLesson(element.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fillRule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                )}
                <div className="popup__lessons--group-date">
                  {`${dateFormat(element.date)}:`}
                </div>
                <div className="popup__lessons--group-description"></div>
                <div>{element.description}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
