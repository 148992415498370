import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import AlertBox from "../../constants/AlertComponent";
import { loginFetch } from "../../constants/FetchComponent";
import DraftJSContainer from "../../constants/DraftJSComponent";
import { BlogForm } from "./BlogForm";
import { TilForm } from "./TilForm";
import { ImposterForm } from "./ImposterForm";

const urlPath = "journal";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loginErrors: "",
      typeOfAlert: null,
    };
  }

  alertType = (payload) => this.setState({ typeOfAlert: payload });
  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });
  directToPath = () => {
    this.setState({ typeOfAlert: null });
    // this.props.history.goBack();
  };
  onResolved = () =>
    console.log(
      "Recaptcha resolved with response: " + this.recaptcha.getResponse()
    );

  handleLogoutClick = () => {
    const url = "/logout";
    fetch(url, { method: "DELETE", credentials: "include" })
      .then(() => this.props.handleLogout())
      .catch((error) => console.log("logout error", error));
  };

  handleLoginSubmit = (event) => {
    event.preventDefault();

    const url = "/api/v1/sessions";
    const { email, password } = this.state;
    const body = { user: { email: email, password: password } };

    loginFetch(
      url,
      body,
      this.alertType,
      this.props.handleLogin,
      this.directToPath
    );
  };

  render() {
    return (
      <Fragment>
        <AlertBox
          {...this.state}
          alertType={this.alertType}
          directToPath={this.directToPath}
        />

        <div className="loginWrapper">
          <h1>DASHBOARD</h1>
          <Link to="/">Back to home</Link>

          <header>
            <h2>Status: {this.props.loggedInStatus}</h2>
          </header>

          <form onSubmit={this.handleLoginSubmit}>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={this.state.email}
              onChange={this.handleChange}
              className="control-form"
              required
            />

            <input
              type="password"
              name="password"
              placeholder="Password"
              value={this.state.password}
              onChange={this.handleChange}
              className="control-form"
              required
            />

            <button type="submit">
              <span>Login</span>
            </button>
            <button type="button" onClick={this.handleLogoutClick}>
              Logout
            </button>
          </form>

          {this.props.user.admin && (
            <div className="dashboard">
              <div className="dashboard__section">
                <TilForm alertType={this.alertType} />

                <ImposterForm alertType={this.alertType} />

                <BlogForm />
              </div>

              {/* <div className="dashboard__article">
              <h2>Article section</h2>
              <form action="" className="dashboard__article--form">
                <input
                  type="text"
                  name="title"
                  id="title"
                  placeholder="title"
                />

                <input
                  type="text"
                  name="author"
                  id="author"
                  placeholder="author"
                />

                <input
                  type="text"
                  name="length"
                  id="length"
                  placeholder="reading length"
                />

                <input type="date" name="date" id="date" placeholder="date" />

                <div className="dashboard__article--draftjs">
                  <DraftJSContainer
                    {...this.state}
                    {...this.props}
                    urlPath={urlPath}
                  />
                </div>
                <button>Submit</button>
              </form>
            </div> */}
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default Login;
