import React, { Fragment } from "react";

const JimmyPhoto = () => {
  return (
    <Fragment>
      <img
        // src="https://lh3.googleusercontent.com/pw/ACtC-3eEVgm_U-MMq9eQw263uq7s9MUvy2gJtDeRBaLby55wpAQBH12EbuXDScRFN5S1Fc_bPQbFtR2pJs8aXxQz_JO3_OZ9od5jsHpcJ2LgueGrWXkPuVfkmTMWpKwDaNc-85ZPZIlbHplrcl98HrKIyujmJA=w1360-h1054-no?authuser=0"
        src="https://media3.giphy.com/media/SYi7uc4CIa78EDg5Y8/giphy.gif"
      ></img>
    </Fragment>
  );
};

export default JimmyPhoto;
