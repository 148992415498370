import React, { Fragment } from "react";
import ReactGA from "react-ga";
import EsoPhoto from "./EsoPhoto";

const gridPicture = {
  gridRow: "3 / span 4",
  gridColumn: "2 / span 3",
  zIndex: "3"
};

const gaEsoHeroku = () => {
  ReactGA.event({
    category: "Button",
    action: "Mobile Eso Heroku Clicked"
  });
};

const gaEsoGit = () => {
  ReactGA.event({
    category: "Button",
    action: "Mobile Eso Git Clicked"
  });
};

const EsoMobile = props => {
  return (
    <Fragment>
      <div
        className="container p-3 "
        style={{
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))",
          borderRadius: props.cornerValue
        }}
      >
        <div className="container p-3 eso-info-mobile">
          <div className="">
            <h2>Project Eso</h2>
          </div>
          <p>
            Project Eso is a capstone project from Launch Academy. Everyday, the
            average American thinks about food up to 4 times a day. More so, if
            you're looking for a good place to eat around town. Enter Project
            Eso! When you create your own profile, you're able to choose how
            much you want to spend, where to spend it, and what kind of food
            you'd like to spend it on.
            {props.readVisible && props.width < 420 && (
              <i onClick={props.handleReadMore}>... read more</i>
            )}
          </p>

          {props.more || props.width > 420 ? (
            <Fragment>
              <p>
                Think Tinder meets Restaurants. The app helps narrows your
                choices. Once you've found your choice, it's saved in the
                database for future implementations.
              </p>
              {!props.readVisible && (
                <i onClick={props.handleReadMore}>read less</i>
              )}
            </Fragment>
          ) : null}
        </div>

        <div style={{ gridPicture }} className="photo">
          <EsoPhoto />
        </div>

        <div className="container p-3 eso-tech-mobile">
          <h2>Tech:</h2>

          <p>React / Ruby on Rails / PostgreSQL / Heroku / Yelp API</p>

          <div className="text-center">
            <div className="btn-group mr-3">
              <a
                href="http://www.projecteso.com/"
                target="_blank"
                className="btn effect01"
                onClick={gaEsoHeroku}
              >
                <span>Demo</span>
              </a>
            </div>

            <div className="btn-group">
              <a
                href="https://github.com/Phu-Bahr/project-eso"
                target="_blank"
                className="btn effect01"
                onClick={gaEsoGit}
              >
                <span>GitHub</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EsoMobile;
