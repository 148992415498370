import React, { Fragment } from "react";
import ReactGA from "react-ga";
import AmazonPhoto from "./AmazonPhoto";

const gridPicture = {
  gridRow: "3 / span 4",
  gridColumn: "2 / span 3",
  zIndex: "3",
};

const gaAmazonHeroku = () => {
  ReactGA.event({
    category: "Button",
    action: "Mobile Amazon Heroku Clicked",
  });
};

const gaAmazonGit = () => {
  ReactGA.event({
    category: "Button",
    action: "Mobile Amazon Git Clicked",
  });
};

const AmazonMobile = (props) => {
  return (
    <Fragment>
      <div
        className="container p-3 "
        style={{
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))",
          borderRadius: props.cornerValue,
        }}
      >
        <div className="container p-3 amazon-info-mobile">
          <div className="">
            <h2>Amazonion</h2>
          </div>
          <p>
            Are you an Amazon addict? Find out and use this app! Amazonion will
            take the last 15 years of your purchase history and display it
            beautifully on 3 custom charts. If you want to dive deeper, there's
            also a table for you to sort through all your items. Have fun with
            it!
          </p>
        </div>

        <div style={{ gridPicture }} className="photo">
          <AmazonPhoto />
        </div>

        <div className="container p-3 amazon-tech-mobile">
          <h2>Tech:</h2>

          <p>React / Next JS / Nivo Charts / Vercel / DropZone</p>

          <div className="text-center">
            <div className="btn-group mr-3">
              <a
                href="https://amazonion.me/"
                target="_blank"
                className="btn effect01"
                onClick={gaAmazonHeroku}
              >
                <span>Demo</span>
              </a>
            </div>

            <div className="btn-group">
              <a
                href="https://github.com/Phu-Bahr/amazonion"
                target="_blank"
                className="btn effect01"
                onClick={gaAmazonGit}
              >
                <span>GitHub</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AmazonMobile;
