import React, { Fragment } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import ReactGA from "react-ga";

const AboutInfo = props => {
  const gaAboutContact = () => {
    ReactGA.event({
      category: "Button",
      action: "About contact me Clicked"
    });
    props.onContactClick();
  };

  return (
    <Fragment>
      <ScrollAnimation
        animateIn="fadeIn"
        delay={1100}
        animateOnce={true}
        className="boxShadow gridInfo"
      >
        <div className="gridAboutInfoContainer">
          <div className="gridInsideInfo">
            <ScrollAnimation animateIn="fadeIn" delay={1500} animateOnce={true}>
              <h3 className="pb-3">
                I'm a freelance front and back end software developer, based out
                of Boston, MA, in pursuit of a full time position.
              </h3>

              <p>
                I wake up and sleep thinking about code, wondering what I can
                add next, how to debug something, or just fixing that alignment
                by 5 more pixels. Learning new techniques to implement into my
                projects excites me like getting that amazon package you forgot
                about. Debugging errors to me is like fighting a new boss in a
                video game. You analyze him, strategize, then follow through
                trial and error until he's defeated.
              </p>

              <p>
                My past work experiences involve network marketing, managing
                operations in a global custodian bank, completing a coding
                bootcamp, and most recently learning data engineering and
                software development at one of the top government SaaS
                companies.
              </p>

              <p>
                Any free time I have available is designated to reading up on
                new changes within the industry, taking online courses to
                further my knowledge, spending time with my family, connecting
                with friends, and helping you build something extraordinary.
              </p>

              <ScrollAnimation
                animateIn="bounce"
                delay={3000}
                animateOnce={true}
              >
                <a className="navFontItem" onClick={gaAboutContact}>
                  Let’s build something together.
                </a>
              </ScrollAnimation>
            </ScrollAnimation>
          </div>
        </div>
      </ScrollAnimation>
    </Fragment>
  );
};

export default AboutInfo;
